import React, { useState } from 'react';
import RoomOfMirrorsThumbnail from '../../images/portfolio/Tristious/room-of-mirrors.png';
import ShadowsFollowThumbnail from '../../images/portfolio/Tristious/shadows-follow.png';
import PortfolioPage from '../../components/PortfolioPage';
import PortfolioPageVideo from '../../components/PortfolioPageVideo';
import Guitar from '../../images/portfolio/Tristious/guitar.png';
import Guitar2 from '../../images/portfolio/Tristious/guitar-2.png';
import GuitarPlayer from '../../images/portfolio/Tristious/guitar-player.gif';
import GuitarPlayer2 from '../../images/portfolio/Tristious/guitar-player-2.gif';
import Sketch from '../../images/portfolio/Tristious/sketch.png';
import Sketch2 from '../../images/portfolio/Tristious/sketch-2.png'

const Tristious = () => {
    
    const [showVideo, setShowVideo] = useState(false); 

    const onVideoSelect = (videoUrl) => {
      setShowVideo(videoUrl)
    }
    return <PortfolioPage 
        img={RoomOfMirrorsThumbnail} 
        url="https://www.youtube.com/embed/edkV2e0wYxs?si=PWZoIZwG__hEQtlR" 
        heroTitle={'Metallica: Room of Mirrors (Official Music Video)'}
        title="Metallica (Tristious)" 
        description="Metallica er et av verdens mest ikoniske heavy metal band. Igjennom et samarbeid med Tristan Zammit som eier ‘Tristous’, produserte vi ca. 12 sekunder med karakter-animasjon til musikkvideoen for ‘Room of Mirrors’, en av sangene i ‘72 Seasons’, som er bandet sitt nyeste album. (2023)"
    >
        <div className="flex  mt-32 lg:px-0 flex-wrap justify-center mb-12">
            <div className="max-w-sm mb-4">
                <img src={Sketch} />
                <div className="my-4"/>
                <img src={Sketch2} />
            </div>
            <div className="md:mx-8"/>
            <div className="max-w-sm">
                <img src={GuitarPlayer2} />
                <div className="my-4"/>
                <img src={GuitarPlayer} />
            </div>
        </div>
        <PortfolioPageVideo title='Metallica: Shadows Follow (Official Music Video)' thumbnailImg={ShadowsFollowThumbnail} url={'https://www.youtube.com/embed/Ui1OBxlWvvE?si=zK4UCwTMdJeEpZFf'} />
        <div className="flex mt-12 flex-wrap md:flex-nowrap">
            <div>
                <img src={Guitar} />
            </div>
            <div>
                <img src={Guitar2} />
            </div>
         
        </div>
    </PortfolioPage>

}

export default Tristious;